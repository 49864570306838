import React from "react"
import { StaticQuery, graphql } from "gatsby"
export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description
            }
          }
        }
      }
    `}
    render={data => (
      <div className="hero-header">
          <div style={{backgroundImage: `url('/assets/arseventus.jpg')`, backgroundSize: 'contain', width: '200px', height: '110px', backgroundRepeat: 'no-repeat'}}>
          </div>
          <br />
          <br />
          <div
          className="primary-content" 
          dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.home.description}}
        />
      </div>
    )}
  />
)